import React from "react"
import '../../node_modules/font-awesome/css/font-awesome.min.css';
import '../shared/css/typography.css';
import '../shared/css/ui-elements.css';
import '../shared/css/styles.css';
import '../shared/css/page.css';
import SEO from "../core/seo"
import About from "../layouts/about/About"
import Layout from "../layouts/Layout"

const AboutPage = props => (       <Layout {...props}>
  <SEO title="About Me"/>
  <About {...props}/>
</Layout>)

export default AboutPage


import React from 'react';
import PropTypes from 'prop-types';
import css from './About.module.css';
import cn from 'classnames';
import { graphql, StaticQuery } from "gatsby"
import { ImageShowAnimated } from "../../shared/components/ImageShowAnimated"
import Layout from "../../pages/training"



export default class About extends React.Component {
  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            bigPic: file(relativePath: { eq: "contacts/img_contacts.png" }) { ...imgContactBig },
            photo_profile: file(relativePath: { eq: "profile/fb_square_angular.png" }) { ...photo },
            gde_web_badge: file(relativePath: { eq: "profile/2019-GDE-Web-Technologies-Badge.png" }) { ...gdeLogo },
            gde_angular_badge: file(relativePath: { eq: "profile/2019-GDE-Angular-Badge.png" }) { ...gdeLogo },
            gde_angular: file(relativePath: { eq: "profile/angular-gde-logo@2x.png" }) { ...gdeLogo },
            codemotion_roma2019: file(relativePath: { eq: "home/codemotion-roma2019.png" }) { ...photo },
          }
        `}
        render={data => <AboutContent {...this.props}  data={data} /> }
      />
    )
  }
}

export class AboutContent extends React.Component {
  render() {
    return (
      <div>

        <a href="http://training.fabiobiondi.io/" target="_blank" rel="noopener noreferrer">
          <div className="button_footer_left bg-angular">
            <i className="fa fa-external-link-square" aria-hidden="true"></i> MY BLOG
          </div>
        </a>


        <div className="page_wrapper">

          <div
            className={cn('page_wrapper_side_text')}
          >
            <div className="page_wrapper_side_text_inner">
              <div style={{display: 'flex'}}>
                <div style={{  marginRight: '1rem' }} className="hide-on-mobile">
                  <img alt="logo" src={this.props.data.gde_angular.childImageSharp.fluid.src} height={150}/>
                </div>
                <div style={{ flexGrow: 1, margin: 'auto'}}>
                  <br/>
                  <h1 className={cn('title_text_l', css.page_logo_name)}>
                    FABIO<span className="text-bold">BIONDI</span>
                  </h1>

                  <h2 className={cn('title_text_m', css.page_logo_second_line)}>
                    Google <span className="hide-on-mobile">Developer</span> Expert
                  </h2>
                </div>
              </div>


              <div className="paragraph">
                <i className="fa fa-map-marker" style={{ textAlign: 'center', width: '2rem'}}></i>
                <span className="text-secondary hide-on-mobile"> LOCATION: </span>
                <strong>TRIESTE</strong>
              </div>

              <div className="paragraph">
                <i className="fa fa-heart" style={{ textAlign: 'center', width: '2rem'}}></i>
                <span className="text-secondary hide-on-mobile"> MAIN SKILLS: </span>
                <strong>ANGULAR, REACT, JS</strong>
              </div>

              <div className="paragraph">
                <i className="fa fa-briefcase" style={{ textAlign: 'center', width: '2rem'}}></i>
                <span className="text-secondary hide-on-mobile"> JOB: </span>
                <strong>FREELANCE</strong>
              </div>

              <div className="paragraph">
                <i className="fa fa-code" style={{ textAlign: 'center', width: '2rem'}}></i>
                <span className="text-secondary hide-on-mobile"> MAIN ACTIVITIES: </span>
                <strong>TRAINER & DEVELOPER</strong>
              </div>

              <br/>

              <img alt="photo_profile"
                   className="fixed-except-mobile"
                   src={this.props.data.photo_profile.childImageSharp.fluid.src}
                   width="100%"
                   style={{ maxWidth: 400, width: '100%'}}/>


              <p className="paragraph">
                Fabio is both a community leader and a frequent contributor in many Italian front-end usergroups
                (<a href="https://www.facebook.com/groups/angularjs.developer.italiani" target="_blank"  rel="noopener noreferrer">Angular</a>,
                <a href="https://www.facebook.com/groups/react.developer.italiani/" target="_blank"  rel="noopener noreferrer"> React</a>,
                <a href="https://www.facebook.com/groups/javascript.developer.italiani" target="_blank"  rel="noopener noreferrer"> Javascript</a>, ...).
                He's a <strong>Google Developer Expert in Web Technologies and Angular</strong> with more than 15 years of experience in developing enterprise level applications, interactive experiences and UI components in several languages and web technologies.
                <br/>
                <br/>

                Currently, he holds meetups, events and training courses all over Italy and he's speaker in a lot of conferences such as CodeMotion, WebAppConf, Angular Day and more...
                <br/>
                <br/>
                His particular specialism is front-end applications and related aspects, both visuals and architectural. He daily uses cutting-edge libraries and frameworks offering training, development, code review and consulting on Angular, React, Typescript/ES6, Redux, NGRX, RxJS, Firebase, D3.js, CreateJS and several other web technologies.


              </p>

              <div className="separator"></div>

              <div className={css.socials}>

                  <a href="https://www.linkedin.com/in/fabiobiondi/" target="_blank"  rel="noopener noreferrer">
                    <i className={cn(css.icon, 'fa fa-linkedin')}></i>
                  </a>
                  <a href="https://www.facebook.com/biondifabio/" target="_blank"  rel="noopener noreferrer">
                    <i className={cn(css.icon, 'fa fa-facebook')}></i>
                  </a>
                  <a href="https://www.instagram.com/biondifabio/" target="_blank"  rel="noopener noreferrer">
                    <i className={cn(css.icon, 'fa fa-instagram')}></i>
                  </a>
                  <a href="https://www.youtube.com/user/BiondiFabio" target="_blank"  rel="noopener noreferrer">
                    <i className={cn(css.icon, 'fa fa-youtube')}></i>
                  </a>
                  <a href="https://twitter.com/biondifabio" target="_blank"  rel="noopener noreferrer">
                    <i className={cn(css.icon, 'fa fa-twitter')}></i>
                  </a>
              </div>

              <div className="separator"></div>

              <p className="paragraph">
                <a href="../contacts">
                  Contact Me
                </a> | <a
                href="http://training.fabiobiondi.io" target="_blank"  rel="noopener noreferrer">
                  My Tech Blog
                </a>
              </p>



              <br/>
              <div className="paragraph">


                <div style={{paddingBottom: 10, textAlign: 'center'}} >

                  <img alt="logo" src={this.props.data.gde_angular_badge.childImageSharp.fluid.src}
                       width="100%"
                       style={{maxWidth: 230, margin: '0.5rem 1rem'}} />
                  <img alt="logo" src={this.props.data.gde_web_badge.childImageSharp.fluid.src}
                       width="100%"
                       style={{maxWidth: 230, margin: '0.5rem 1rem'}} />
                  <br/>

                  <img alt="photo_profile"
                       className="fixed-except-mobile"
                       src={this.props.data.photo_profile.childImageSharp.fluid.src}
                       width="100%"
                       style={{ maxWidth: 300 }}/>

                </div>

              </div>



            </div>
          </div>

          <ImageShowAnimated
            className={cn('page_wrapper_side', 'page_wrapper_side_image', 'clip-circle')}
            image={this.props.data.codemotion_roma2019.childImageSharp.fluid.src}
          />
        </div>
      </div>
    )

  }
}

About.propTypes = {
  onGotoPage: PropTypes.func,
}

